/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .slide-in {
    animation: slideInFromRight 1s ease-out forwards;
  }